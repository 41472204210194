import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Location, Router } from '@reach/router';
import { ToastContainer } from 'react-toastify';

import { library } from '@fortawesome/fontawesome-svg-core';
import {
    faChevronLeft as faSolidChevronLeft,
    faChevronRight as faSolidChevronRight,
    faCheck as faSolidCheck,
    faCaretDown as faSolidCaretDown,
    faArrowLeft as faSolidArrowLeft,
    faExclamationCircle as faSolidExclamationCircle,
    faCircle as faSolidCircle,
    faCircleNotch as faSolidCircleNotch,
    faCheckCircle as faSolidCheckCircle,
} from '@fortawesome/pro-solid-svg-icons';
import {
    faShoppingBasket as faLightShoppingBasket,
    faArrowLeft as faLightArrowLeft,
} from '@fortawesome/pro-light-svg-icons';

import SEO from '@components/SEO';
import Footer from '@components/Footer';

// main site style
import '@styles/style.scss';

import styles from './styles.module.scss';
import Header from '../Header';

library.add(
    // solid icons
    faSolidChevronLeft,
    faSolidChevronRight,
    faSolidCheck,
    faSolidCaretDown,
    faSolidArrowLeft,
    faSolidExclamationCircle,
    faSolidCircle,
    faSolidCircleNotch,
    faSolidCheckCircle,
    // ...
    //
    // brand icons
    // faBrandFacebookF,
    // ...
    //
    // light icons
    faLightShoppingBasket,
    faLightArrowLeft
    // ...
);

const Layout = ({ children, className = '' }) => {
    return (
        <div className={`${styles.app} ${className}`}>
            <SEO />
            <Header />

            <main className={styles.main}>
                {children}
            </main>
            <footer className={styles.footer}>
                {' '}
                <Footer />
            </footer>

            {/* If using toasts, always keep within div to prevent gatsby html error in new versions */}
            <div>
                <ToastContainer />
            </div>
        </div>
    );
};

const LayoutWithLocation = (props) => (
    <Location>{(locationContext) => <Layout {...locationContext} {...props} />}</Location>
);

export default LayoutWithLocation;
