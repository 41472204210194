import React from 'react';
import { Grid, Row, Col, Divider } from '@components/Grid';
import styles from './header.module.scss';
import Image from '../Card/Image';
import { useStaticQuery, graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import Basket from '../Basket';
const Header = () => {
    const {
        Header: {
            frontmatter: { logo, cart, search },
        },
    } = useStaticQuery(graphql`
        query Logo {
            Header: markdownRemark(fileAbsolutePath: { regex: "/home/" }) {
                frontmatter {
                    logo {
                        publicURL
                    }
                    cart {
                        publicURL
                    }
                    search {
                        publicURL
                    }
                }
            }
        }
    `);
    return (
        <Grid noMargin>
            <Row center="xs">
                <Col xs={12}>
                    <Link
                        to="/"
                    >
                        <img alt="Ballistic Artwork Logo" className={styles.logo} src={logo.publicURL} />
                    </Link>
                </Col>
            </Row>

            <Basket />
        </Grid>
    );
};

export default Header;
