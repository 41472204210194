import React from 'react';
import styles from './footer.module.scss';
import { useStaticQuery, graphql } from 'gatsby';
import { Grid } from 'react-flexbox-grid';
const Footer = () => {
    const {
        Footer: {
            frontmatter: { facebook, insta },
        },
    } = useStaticQuery(graphql`
        query {
            Footer: markdownRemark(fileAbsolutePath: { regex: "/home/" }) {
                frontmatter {
                    facebook {
                        publicURL
                    }
                    insta {
                        publicURL
                    }
                }
            }
        }
    `);
    return (
        <div className={styles.footerMain}>
            <Grid>
                <div className={styles.content}>
                    <div className={styles.left}>
                        <a href="https://www.facebook.com/ballisticartwork/" target="_blank">
                            <img src={facebook.publicURL}></img>
                        </a>
                        <a href="https://www.instagram.com/ballisticartwork/" target="_blank">
                            <img src={insta.publicURL}></img>
                        </a>
                        <span>© {new Date().getFullYear()} Copyright, BALLISTIC ARTWORK.</span>
                    </div>
                    <div className={styles.right}>
                        Website Designed by{' '}
                        <a href="https://elementsoftworks.co.uk/" target="_blank">
                            Element Softworks Ltd
                        </a>
                    </div>
                </div>
            </Grid>
        </div>
    );
};

export default Footer;
