import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { graphql, StaticQuery } from 'gatsby';

const SEO = (props) => {
    const { product } = props;

    return (
        <StaticQuery
            query={pageQuery}
            render={({
                site: {
                    siteMetadata: {
                        title,
                        description,
                        url,
                        twitterUsername,
                    },
                },
            }) => {
                const seo = {
                    htmlAttributes: { lang: 'en' },
                    title,
                    description,

                    url,
                    article: false,
                };

                return (
                    <Helmet>
                        <title>{product ? product.name : title}</title>
                        <meta name="description" content={seo.description} />
                        <meta property="og:url" content={seo.url} />
                        {(seo.article ? true : null) && (
                            <meta property="og:type" content="article" />
                        )}
                        <meta property="og:title" content={product ? product.name : title} />\
                        {seo.description && (
                            <meta property="og:description" content={seo.description} />
                        )}
                        <meta
                            property="og:image"
                            content={
                                product?.image?.image?.childImageSharp?.fluid?.src
                                    ? `https://ballisticartwork.com${product?.image?.image?.childImageSharp?.fluid?.src}`
                                    : 'https://ballisticartwork.com/preview.png'
                            }
                        />
                        <meta name="twitter:card" content="summary_large_image" />
                        {twitterUsername && (
                            <meta name="twitter:creator" content={twitterUsername} />
                        )}
                        <meta name="twitter:title" content={product ? product.name : title} />
                        {seo.description && (
                            <meta name="twitter:description" content={seo.description} />
                        )}
                        <link
                            rel="apple-touch-icon"
                            sizes="180x180"
                            href={require('../favicon/apple-touch-icon.png')}
                        />
                        <link
                            rel="icon"
                            type="image/png"
                            sizes="32x32"
                            href={require('../favicon/favicon-32x32.png')}
                        />
                        <link
                            rel="icon"
                            type="image/png"
                            sizes="16x16"
                            href={require('../favicon/favicon-16x16.png')}
                        />
                        <link rel="shortcut icon" href={require('../favicon/favicon.ico')} />
                        <link rel="manifest" href="/favicon/site.webmanifest" />
                        <link
                            rel="mask-icon"
                            href="/favicon/safari-pinned-tab.svg"
                            color="#5bbad5"
                        />
                        <link rel="shortcut icon" href="/favicon/favicon.ico" />
                        <meta name="msapplication-TileColor" content="#ffffff" />
                        <meta name="msapplication-config" content="/favicon/browserconfig.xml" />
                        <meta name="theme-color" content="#ffffff" />
                        {product && <meta property="product:availability" content="in stock" />}
                        {product && <meta property="product:condition" content="new" />}
                        {product && (
                            <meta property="product:price:amount" content={product.price} />
                        )}
                        {product && (
                            <meta property="product:price:currency" content={product.currency} />
                        )}
                        {product && (
                            <meta property="product:retailer_item_id" content={product.id} />
                        )}
                        {/* <meta property="product:item_group_id" content="fb_tshirts"/> */}
                        <meta property="product:brand" content="Ballistic Artwork" />
                    </Helmet>
                );
            }}
        />
    );
};

export default SEO;

const pageQuery = graphql`
    query SEOQuery {
        site {
            siteMetadata {
                title
                titleTemplate
                description
                url
                twitterUsername
            }
        }
    }
`;
